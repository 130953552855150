import { usePolicyService } from "~/src/services/PolicyService";
import {
  abortNavigation,
  createError,
  defineNuxtRouteMiddleware,
} from "#imports";

export default defineNuxtRouteMiddleware(async (to, _from) => {
  const { useGetPolicyQuery } = usePolicyService();
  const result = await useGetPolicyQuery(String(to.params.id));

  if (to.params.id === "new") return true;
  if (!result.data.value)
    return abortNavigation(
      createError({
        statusCode: 404,
        message: "Policy not found",
      })
    );
});
